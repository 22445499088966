import React, { useState } from 'react';
import { Paper, Typography, Button, TextField, List, ListItem, ListItemText, Divider, IconButton } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import { Helmet } from 'react-helmet';
import './Valuation.css';

interface Item {
  name: string;
  quantity: number;
  unitPrice: number;
  unit: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Nazwa jest wymagana'),
  quantity: Yup.number()
    .required('Ilość jest wymagana')
    .min(1, 'Ilość musi być większa niż 0')
    .max(10000, 'Ilość nie może być większa niż 10000'),
  unitPrice: Yup.number()
    .required('Cena jednostkowa jest wymagana')
    .min(0.01, 'Cena jednostkowa musi być większa niż 0')
    .max(1000000, 'Cena jednostkowa nie może być większa niż 1000000'),
  unit: Yup.string().required('Jednostka jest wymagana'),
});

const Valuation: React.FC = () => {
  const [items, setItems] = useState<Item[]>([]);

  const handleAddItem = (values: Item, { resetForm }: any) => {
    const newItem: Item = {
      name: values.name,
      quantity: values.quantity,
      unitPrice: values.unitPrice,
      unit: values.unit,
    };
    setItems([...items, newItem]);
    resetForm();
  };

  const handleRemoveItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  return (
    <div className="valuation-container">
      <Helmet>
        <title>Wyceny</title>
      </Helmet>
      {/* Header */}
      <Typography variant="h1" className="header">
        Kalkulator wycen usług (Beta)
      </Typography>

      {/* Description */}
      <Typography variant="h2" className="description">
        Oblicz wartość swoich usług w prosty sposób. Wprowadź nazwę, cenę jednostkową, ilość i jednostkę, a następnie kliknij przycisk "Dodaj". Wszystkie zostaną wyświetlone po prawej stronie. Możesz je usunąć, klikając na ikonę kosza. Poniżej znajdziesz podsumowanie. Wartość  jest obliczana na bieżąco. W razie potrzeby możesz zmienić wartości lub usunąć je. Powodzenia!
      </Typography>

      <div className="main-content">
        {/* Main */}
        <div className="form-section">
          {/* Form */}
          <Paper
            sx={{
              padding: '2vh 4vw',
              borderRadius: '2em',
              boxShadow: '0 0.5em 1.5em rgba(0, 0, 0, 0.3)',
              backgroundColor: '#1e1e1e',
              border: '1px solid #333333',
              color: '#ffffff',
            }}
            className="form-paper"
          >
            <Typography variant="h5" className="form-title">
              Dodaj
            </Typography>
            <Formik
              initialValues={{ name: '', quantity: 0, unitPrice: 0, unit: '' }}
              validationSchema={validationSchema}
              onSubmit={handleAddItem}
            >
              {({ errors, touched, handleChange }) => (
                <Form>
                  <Field
                    as={TextField}
                    label="Nazwa"
                    name="name"
                    fullWidth
                    margin="normal"
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    onChange={handleChange}
                    sx={{
                      borderRadius: '2em',
                      marginBottom: '1.5em',
                      backgroundColor: '#333333',
                      color: '#ffffff',
                    }}
                    InputLabelProps={{ style: { color: '#bbbbbb' } }}
                    InputProps={{ style: { color: '#ffffff' } }}
                  />
                  <div className="price-unit-container">
                    <Field
                      as={TextField}
                      label="Cena jednostkowa"
                      name="unitPrice"
                      type="number"
                      fullWidth
                      margin="normal"
                      error={touched.unitPrice && !!errors.unitPrice}
                      helperText={touched.unitPrice && errors.unitPrice}
                      onChange={handleChange}
                      sx={{
                        borderRadius: '2em',
                        marginRight: '1.5em',
                        backgroundColor: '#333333',
                        color: '#ffffff',
                      }}
                      InputLabelProps={{ style: { color: '#bbbbbb' } }}
                      InputProps={{
                        style: { color: '#ffffff' },
                        inputProps: {
                          step: 0.01,
                          min: 0,
                          max: 1000000,
                        },
                      }}
                    />
                    <Field
                      as={TextField}
                      label="Jednostka"
                      name="unit"
                      fullWidth
                      margin="normal"
                      error={touched.unit && !!errors.unit}
                      helperText={touched.unit && errors.unit}
                      onChange={handleChange}
                      sx={{
                        borderRadius: '2em',
                        backgroundColor: '#333333',
                        color: '#ffffff',
                      }}
                      InputLabelProps={{ style: { color: '#bbbbbb' } }}
                      InputProps={{ style: { color: '#ffffff' } }}
                    />
                  </div>
                  <Field
                    as={TextField}
                    label="Ilość"
                    name="quantity"
                    type="number"
                    fullWidth
                    margin="normal"
                    error={touched.quantity && !!errors.quantity}
                    helperText={touched.quantity && errors.quantity}
                    onChange={handleChange}
                    sx={{
                      borderRadius: '2em',
                      marginBottom: '1.5em',
                      backgroundColor: '#333333',
                      color: '#ffffff',
                    }}
                    InputLabelProps={{ style: { color: '#bbbbbb' } }}
                    InputProps={{
                      style: { color: '#ffffff' },
                      inputProps: {
                        step: 0.01,
                        min: 1,
                        max: 10000,
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      borderRadius: '1.5em',
                      marginTop: '2vh',
                      backgroundColor: '#007bff',
                      color: '#ffffff',
                      '&:hover': {
                        backgroundColor: '#0056b3',
                      },
                    }}
                  >
                    Dodaj
                  </Button>
                </Form>
              )}
            </Formik>
          </Paper>

          {/* Summary */}
          <Paper
            sx={{
              padding: '2vh 4vw',
              borderRadius: '2em',
              boxShadow: '0 0.5em 1.5em rgba(0, 0, 0, 0.3)',
              backgroundColor: '#1e1e1e',
              border: '1px solid #333333',
              color: '#ffffff',
              marginTop: '2vh',
            }}
            className="summary-paper"
          >
            <Typography variant="h5" className="summary-title">
              Podsumowanie
            </Typography>
            <Typography variant="body1">
              Liczba : {items.length}
            </Typography>
            <Typography variant="body1">
              Łącznie: {items.reduce((acc, item) => acc + item.quantity * item.unitPrice, 0).toFixed(2)} zł
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="button"
              sx={{
                borderRadius: '1.5em',
                marginTop: '2vh',
                backgroundColor: '#007bff',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#0056b3',
                },
              }}
              disabled={true || items.length === 0}
            >
              Wygeneruj pdf
            </Button>
          </Paper>
        </div>
        <div className='list-container'>
          {/* List */}
          <Paper
            sx={{
              padding: '2vh 4vw',
              borderRadius: '2em',
              boxShadow: '0 0.5em 1.5em rgba(0, 0, 0, 0.3)',
              backgroundColor: '#1e1e1e',
              border: '1px solid #333333',
              color: '#ffffff',
            }}
            className="list-paper"
          >
            <Typography variant="h5" className="list-title">
              Lista
            </Typography>
            <List>
              {items.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      primary={item.name}
                      secondaryTypographyProps={{ sx: { color: '#ffffff' } }}
                      secondary={`${item.quantity.toFixed(2)} ${item.unit} x ${item.unitPrice.toFixed(2)} zł/${item.unit}`}
                    />
                    <IconButton onClick={() => handleRemoveItem(index)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                  {index < items.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default Valuation;
