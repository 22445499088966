import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Valuation from '../../Pages/Valuation/Valuation';
import { ROUTES } from '../../Utils/Routes';
import Policy from '../../Pages/Policy/Policy';

const AppRoutes: React.FC = () => {
  return (
      <Routes>
        <Route path={ROUTES.HOME} element={<Valuation />} />
        <Route path={ROUTES.VALUATION} element={<Valuation />} />
        <Route path={ROUTES.POLICY} element={<Policy />} />
      </Routes>
  )
};

export default AppRoutes;
