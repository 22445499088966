import { Link } from "react-router-dom";
import "../Navbar/Navbar.css";
import "./Footer.css";
import { ROUTES } from "../../Utils/Routes";

const Footer = () => {
  return (
    <footer className="foot">
      <span>
        &copy; 2024 PK. All Rights Reserved
      </span>
      <div>
        <Link to={ROUTES.POLICY} className="link-policy" >Polityka Prywatności</Link>
      </div>
    </footer>
  );
};

export default Footer;
