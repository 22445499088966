import React from 'react';
import './Policy.css';

const Policy: React.FC = () => {
    return (
        <div className='policy-div'>
            <h1>Polityka Prywatności</h1>
            <p className='policy-desc'>Niniejsza Polityka Prywatności wyjaśnia, w jaki sposób Serwis [nazwa strony] (dalej: „Serwis”) korzysta z plików cookie i narzędzi reklamowych, takich jak Google Ads.</p>

            <div >
                <h2>1. Pliki cookie i Google Ads</h2>
                <p className='policy-point'>Serwis wykorzystuje pliki cookie w celu personalizacji treści reklam dostarczanych przez Google Ads. Pliki cookie umożliwiają zbieranie anonimowych danych o aktywności użytkowników na stronie, co pozwala na wyświetlanie spersonalizowanych reklam. Google Ads może używać plików cookie do wyświetlania reklam opartych na wcześniejszych wizytach użytkownika w Serwisie. Użytkownicy mogą zarządzać swoimi preferencjami dotyczącymi reklam w ustawieniach reklam Google lub wyłączyć personalizację reklam za pośrednictwem narzędzi takich jak Network Advertising Initiative.</p>
            </div>
            <div >
                <h2>2. Zakres zbieranych danych</h2>
                <p className='policy-point'>Serwis sam nie zbiera danych osobowych użytkowników, jednak zewnętrzni dostawcy reklamowi, tacy jak Google, mogą zbierać dane anonimowe w celu personalizacji reklam.</p>
            </div>
            <div >
                <h2>3. Prawa użytkowników</h2>
                <p className='policy-point'>Użytkownicy mają prawo do zarządzania plikami cookie w swoich przeglądarkach, a także do blokowania lub usuwania plików cookie za pomocą ustawień przeglądarki internetowej.</p>
            </div>
            <div >
                <h2>4. Zmiany w Polityce Prywatności</h2>
                <p className='policy-point'>Serwis zastrzega sobie prawo do zmiany niniejszej Polityki Prywatności w przypadku wprowadzenia nowych funkcjonalności lub zmian w zakresie usług.</p>
            </div>
            <div className='text-right'>
                <span>Ostatnia modyfikacja 15.09.2024</span>
            </div>
        </div>
    );
};

export default Policy;