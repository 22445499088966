import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { ROUTES } from '../../Utils/Routes';

const Navbar: React.FC = () => {
  return (
    <div className="nav">
      <div className="navbar-container">
        <div className="navbar-grid">
          {/* Jeśli zdecydujesz się dodać przycisk menu, odkomentuj poniższy kod */}
          {/* <div className="menu-button">
            <button type="button" onClick={toggle}>
              <img src={menuIcon} alt="menu" className='menu-icon' />
            </button>
          </div> */}
          <div className="navbar-brand">
            <Link to={ROUTES.HOME}>
              <p className="link">⌂</p>
            </Link>
          </div>
          {/* <div className="navbar-links">
            <ul>
              <li>
                <Link to={ROUTES.VALUATION}>
                  <p className="link">Wyceny</p>
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
